
.z-depth-1 {
    -webkit-box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
    box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
}

.z-depth-2 {
    -webkit-box-shadow: 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.3);
    box-shadow: 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.3);
}
.z-depth-3 {
    -webkit-box-shadow: 0 8px 17px 2px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12), 0 5px 5px -3px rgba(0,0,0,0.2);
    box-shadow: 0 8px 17px 2px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12), 0 5px 5px -3px rgba(0,0,0,0.2);
}
.z-depth-4 {
    -webkit-box-shadow: 0 16px 24px 2px rgba(0,0,0,0.14), 0 6px 30px 5px rgba(0,0,0,0.12), 0 8px 10px -7px rgba(0,0,0,0.2);
    box-shadow: 0 16px 24px 2px rgba(0,0,0,0.14), 0 6px 30px 5px rgba(0,0,0,0.12), 0 8px 10px -7px rgba(0,0,0,0.2);
}
.z-depth-5 {
    -webkit-box-shadow: 0 24px 38px 3px rgba(0,0,0,0.14), 0 9px 46px 8px rgba(0,0,0,0.12), 0 11px 15px -7px rgba(0,0,0,0.2);
    box-shadow: 0 24px 38px 3px rgba(0,0,0,0.14), 0 9px 46px 8px rgba(0,0,0,0.12), 0 11px 15px -7px rgba(0,0,0,0.2);
}

.raise-1 {
    z-index: 1;
}

.raise-2 {
    z-index: 2;
}

.raise-3 {
    z-index: 3;
}
 
.raise-4 {
    z-index: 4;
}

.lower-1 {
    z-index: -1;
}

.lower-1 {
    z-index: -2;
}

.lower-3 {
    z-index: -3;
}

.lower-4 {
    z-index: -4;
}

.master-progress {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9999;
    background-color: #231f20;
    border-top: solid 1px #ddd;

    .progress-wrapper {
        padding-top: $spacer;
        padding-bottom: $spacer;
    }

    .progress-label span {
        background-color: #484646;
        color: $yellow;
    }

    .progress-percentage span {
        color: $white;
    }

    .bg-default {
        background-color: $yellow !important;
    }

}

.disclaimer {

    color: #8c8c8c;
    font-weight: bold;
    background-color: #242223;

}

.page {

    &#page-3 {
        hr {
            margin-top: 40px;
            margin-bottom: 40px;
        }
    }

    h1, .display-1 {
        letter-spacing: 0;
        font-size: 42px;
        line-height: 40px;
        color: #252223;
        max-width: 500px;
        font-family: IntroBlack;

        span {
            display: inline;
        }

    }

    @media(max-width: 600px) {
        h1, .display-1 {
            font-size: 38px;
        }
    }

    h3, .display-3 {
        letter-spacing: 0;
        font-size: 23px;
        line-height: 32px;
        color: #252223;
        font-weight: 700;
        font-family: IntroBlack;

        span {
            display: inline;
        }

    }

    .display-1 {
        max-width: 100%;
    }

    .smallprint {
        font-size: 14px !important;
        line-height: 16px;
        margin-bottom: 6px;
        color: #666;
        font-weight: normal;
        font-family: Roboto !important;

        &.contract-indent {
            margin-left: 30px;
            line-height: 16px;
            font-size: 11px !important;
        }
    }

    .pay-buttons {
        padding-bottom: 20px;

        .col-left {
            border-right: solid 1px #aaa;
        }
    }

}

.btn {
    &.btn-yellow {
        background-color: $yellow;
        color: #231F21;
        font-family: 'Lato', sans-serif;
        font-weight: 900;
        font-size: 17px;
        min-width: 250px;
        text-align: center;
        padding: 25px 32px !important;
    }
    &.btn-success {
        background: #006400 !important;
        color: white !important;
        font-family: 'Lato', sans-serif;
        font-weight: 900;
        font-size: 17px;
        min-width: 250px;
        text-align: center;
        padding: 25px 32px !important;
    }
}

.modal {

    .modal-title {
        font-size: 22px;
        font-weight: 700;
        line-height: 1.5;
    }

    .btn {
        &.btn-yellow {
            min-width: 150px;
            padding: 12px 16px !important;

        }
    }
}

.roadside-header {

    border-bottom: solid 1px #eee;

    a:hover {
        h4 {
            background-color: $green;
            border: solid 2px lighten($green, 10%);
            outline: none;
        }

        h4, i {
            color: $white !important;
        }
    }
    h4 {
        border: solid 2px #eee;
        border-radius: 40px;
        display: inline-block;
        padding-right: 20px;
        padding-left: 20px;
        padding-top: 5px;
        padding-bottom: 5px;
    }
}

@media(max-width: 600px) {

    &.py-5 {
        padding-top: 15px !important;
        padding-bottom: 10px !important;
    }

    .roadside-header {
        span {
            display: none;
        }

        .align-self-center {
            text-align: center;
        }

        a {
            width: 100%;
            display: inline-block;
            margin-top: 10px;
            h4 {
                display: block;
                text-align: center;
            }
        }
    }

}

iframe {
    height: 500px;
}

@media(max-width: 900px) {
    iframe {
        height: 800px;
    }
}