.form-control:disabled {
    background-color: $gray-300 !important;
}

select {
    -webkit-appearance: none !important;
}
.form-control:disabled,
.form-control[readonly] {
    background-color: $input-bg;
    opacity: 0.7;
}
.form-control:focus {
    color: #8898aa;
    background-color: #fff;
    border-color: #06405C;
    outline: 0;
    // box-shadow: $box-shadow-sm;
}
.rdtPicker .rdtTimeToggle:before {

    content: "Time Select";
}

.form-control {
    background: #f8f8ff;
}

.form-group {
    margin-bottom: $spacer;
}

.custom-checkbox .custom-control-label {

    &::before {
        top: -0px;
    }

    &::after {
        top: -0px;
    }

}

.react-datepicker-wrapper {
    display: block;

    .react-datepicker__input-container {
        position: relative;
        display: inline-block;
        width: 100%;

        input {
            width: 100%;
            background: $body-bg;
            padding: $input-btn-padding-y $input-btn-padding-x;
            border-radius: $border-radius;
            color: $input-color;

            border: solid 1px $input-border-color;
            border-width: $input-border-width;
            box-shadow: $input-box-shadow;
            &:active {
                background: $input-bg;
            }
        }

    }

}

.date-picker-wrapper {

    &.invalid {
        input {
            border: solid 1px $red;
        }
    }

    &.valid {
        input {
            border: solid 1px $green;
        }
    }
}

label {
    margin-bottom: $spacer / 2.5;
}

@media(max-width: 576px) {

    select, input, textarea {
        font-size: 16px !important;
    }

    .form-control  {

        padding: .2rem .7rem !important;
        height: calc(2rem + 2px) !important;

        select, input, textarea {
            font-size: 16px !important;
        }
    }

}

.form-group {

    margin-bottom: 24px;

    .upload-input {
        padding: 25px 22px 25px 22px !important;
        padding-top: 25px !important;
        padding-bottom: 25px !important;
        background-color: $yellow;
        font-weight: bold;
        color: $black;
    }

    .form-control {
        background-color: $white;
        padding: 25px 22px 25px 22px !important;
        padding-top: 25px !important;
        padding-bottom: 25px !important;
        border: 2px solid #e7e7e7;
        border-top-color: none;
        border-left-color: none;
        color: #221f1f !important;
        font-size: 17px !important;
        font-weight: 900 !important;
        font-family: 'Lato', sans-serif;
        border-radius: 2px;
        line-height: 1.4rem;
        height: auto;
    
        &:focus {
            background: rgba(233, 245, 251, 1) !important;
            box-shadow: none;
            border: 2px solid #e7e7e7;
    
        }
    }

    @media(max-width: 600px) {
        .form-control {
            background-color: $white;
            padding: 25px 22px 25px 22px !important;
            padding-top: 10px !important;
            padding-bottom: 10px !important;
            height: 60px !important;
        }
    }

}